<template>
    <Loadings v-show="loading"/>
    <router-view v-slot="{ Component,route }">
        <transition>
            <keep-alive :include="route.name==='Home'?[]:[
                        route.name==='createArticle'||route.name==='visitorEditor'?'':'huoke',
                        route.name==='reviewed'||route.name==='visitorEditor'?'':'articleMana',
                        route.name==='caseEditor'?'':'cases',
                        route.name==='createPoster'?'':'posterIndex',
                        route.name==='buildSiteEditor'?'':'buildSite',
                        route.name==='posterVisitors'?'':'posterDetail',
                        route.name==='posterVisitors'?'':'addPoster',
                        route.name==='editVideo'||route.name==='createVideo'?'':'videoList',]">
                <component :is="Component"/>
            </keep-alive>
        </transition>
    </router-view>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import Loadings from "@/components/Loading";
export default defineComponent({

  // route.name==='editVideo'||route.name==='createVideo'?'':


  components: {
    Loadings,
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters.loading);
    return {
      loading,
    };
  },
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  text-align: center;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

import { post, get, del } from '@/utils/request';
/**
 * 获取公费电话详情
 * @param params
 */
export function getUserCard() {
  return get(
    '/user/usercard/getUserCard',
  );
}

export function saveUserCard(param) {
  return post(
    '/user/usercard/saveUserCard',
    param,
  );
}

export function deleteCardCaseApp(param) {
  return del(
    `/case/deleteCardCaseApp/${param}`
  )
}

export function createConsult(param) {
  return post(
    `/article/createConsult`,
    param
  )
}
/**
 * 增加用户转发分享日志
 * @param {*} param
 * 1-文章 2-案例 3-视频 4-名片 5-微官网
 */
 export function addForwardLog(param){
  return post(
    `/user/userForward/addForwardLog?forwardType=${param}`,
  )
}
//获取名片的推荐内容
export function getRecommend(params) {
  return get(
    '/user/userCardRecommend/getRecommend',
    params,
  );
}
//更新名片推荐内容
export function saveRecommend(params) {
  return post(
    `/user/userCardRecommend/saveRecommend`,
    params
  )
}
//删除名片推荐内容
export function deletedRecommend(params) {
  return post(
    `/user/userCardRecommend/deletedRecommend`,
    params
  )
}
//
//获取小程序url
export function getUserCardUrl() {
  return get(
      `/user/usercard/getUserCardUrl/2`,
  );
}
//获取海报个人信息
export function getUserCardByPoster() {
  return get(
      `/user/usercard/getUserCardByPoster`,
  );
}


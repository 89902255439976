<template>
  <div class="userCard" >
    <van-sticky>
      <van-nav-bar
        right-text="编辑"
        left-arrow
        safe-area-inset-top
        v-if="showHeader"
        @click-left="handleReturn"
        @click-right="handleLinkToModifyUserCard"
      >
        <template #title>
          <div class="head-title">我的名片</div>
        </template>
        <template #left>
          <van-icon name="arrow-left" color="#161719" />
        </template>
        <template #right>
          <div class="header-btn-right">编辑</div>
        </template>
      </van-nav-bar>
    </van-sticky>

    <main class="main" id="cardScroll">
      <van-skeleton title avatar :row="5" :loading="loading">
        <div class="personal-info">
          <div class="head-image">
            <img :src="headImg" />
          </div>
          <div class="info">
            <img :src="require('../../../assets/user-card2.png')" alt="" />
            <div style="position: absolute">
              <div class="name">
                {{ cardInfo.createId?cardInfo.name:cardInfo.defaultName }}
              </div>
              <div class="title des">
                {{ cardInfo.roleName }}
              </div>
              <div class="phone des">
                <icon-svg iconClass="iconsmartphone1" />
                {{ cardInfo.createId?cardInfo.contactNum:cardInfo.defaultContactNum }}
              </div>
              <div class="company des">
                <icon-svg iconClass="iconbox" />
                {{ cardInfo.merchantName }}
              </div>
            </div>
          </div>
        </div>
      </van-skeleton>

      <div class="description">
        <van-skeleton title :row="8" :loading="loading">
          <div class="title">自我介绍</div>
          <div>
            <video
                :src="videoUrl"
                v-show="videoUrl"
                loop
                preload="auto"
                playsinline
                webkit-playsinline="true"
                x5-video-player-type="h5-page"
                x5-video-player-fullscreen="true"
                x5-playsinline
                x-webkit-airplay="allow"
                x5-video-orientation="portrait"
                :playOrPause="playOrPause"
                @click="playOrPauseVideo"
                :poster="require('../../../assets/userCard.png')"
            ></video>
            <img
                v-show="videoUrl"
                class="icon_play"
                @click="playvideo"
                :src="require('../../../assets/play.png')"
            />
          </div>
          <div class="content">
            {{ cardInfo.createId?cardInfo.selfIntro:defaultInfo }}
          </div>
        </van-skeleton>
      </div>
      <div class="user-tags">
        <van-skeleton title :row="4" :loading="loading">
          <div class="title">印象标签</div>
          <span
              class="tag-item"
              v-for="(item, index) in impressionLabel"
              :key="index"
          >
          <van-tag type="primary">{{ item }}</van-tag>
        </span>
        </van-skeleton>
      </div>

      <div class="company-desc">
        <div class="company-desc-top">
          <span class="company-title">公司介绍</span>
          <span class="compnay-btn" @click="handleLinkToOfficialWebsite"
            >查看官网</span
          >
        </div>
        <div v-if="cardInfo.isSetWebsite === 0">
          <div class="company-info">
            <img :src="compnayLogo" alt="" />
            <div class="company-info-describe">
              <p>{{ websiteBriefVO.merchantName }}</p>
            </div>
          </div>
          <div class="company-introduce">
            <span>{{ websiteBriefVO.merchantProfile }}</span>
          </div>
        </div>
      </div>
      <div class="title case">我的推荐</div>
      <!-- <case-item
        v-for="(item, index) in cardInfo.myCaseList"
        :key="index"
        :caseInfo="item"
      /> -->
      <recommend-Item
       v-for="item in myRecommendList"
       :key="item.id"
       :recommendInfo="item"
      >
      <!-- <recommend-Item> -->

      </recommend-Item>
      <div class="more-recomond" v-if="showMoreRecommend" @click="loadMoreRecommend">更多推荐 <icon-svg  iconClass="iconchevron-down" /></div>
      <div class="recommend">
        <div class="title">我的照片</div>
        <img
          v-for="item in userPics"
          :key="item"
          :src="item.url"
          style="object-fit: cover"
        />
      </div>
      <div class="bottom-btns">
<!--        <van-button style="width: 50%" @click="handleShareToWx"-->
<!--          >微信分享</van-button-->
<!--        >-->
<!--        <van-button style="width: 50%" @click="handleLinkToQrcode"-->
<!--          >二维码分享</van-button-->
<!--        >-->
        <van-button type="primary" block color="#FF6E01" @click="handleShareToWx">分享</van-button>
      </div>
    </main>

    <!-- 弹出分享框 -->
    <div class="share_box">
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="options"
        @select="onSelectShare"
        cancel-text="取消"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
    <van-overlay :show="showOverlay" z-index="999">
      <div class="wrapper">
        <div class="content-box" v-if="showImg" ref="imageWrapper">
          <div class="head-box">
            <img :src="headImg" crossOrigin="anonymous" />
            <span class="polygon"></span>
            <span class="msg">您好，这是我的名片， 请惠存，谢谢！</span>
          </div>
          <div class="body-box">
            <div class="user-name">{{ cardInfo.name }}</div>
            <div class="merchant-name">{{ cardInfo.merchantName }}</div>
            <div>
              <img class="qr-code" :src="cardInfo.qrCode" alt />
            </div>
            <div class="btm-msg">· 扫一扫了解我 ·</div>
          </div>
        </div>
        <div v-else>
          <img class="card-img" :src="imgSrc" ref="cardImgRef" />
          <div style="display: flex;justify-content: space-around">
               <van-button color="#FF6E01" @click="toSavePic">保存到手机</van-button>
               <van-button color="#FF6E01" @click="handleCancelSave">取消保存</van-button></div>
          </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed, reactive, toRefs, onUnmounted, ref, nextTick } from "vue";
import notfound from "@/assets/head-img-not-found.png";
import { getUserCard,addForwardLog,getRecommend,getUserCardUrl} from "@/api/user/userCard";
import { flutterShowToast } from "@/utils/appChannel";
import { Dialog, Toast } from "vant";
// import caseItem from "@/components/caseItem.vue";
import recommendItem from "../../../components/recommendItem.vue"
import { searchFileList } from "@/api/file";
import html2canvas from "html2canvas";
import { copyStr } from "@/utils/commonUtil";
export default {
   name:'card',
   activated(){
    document.getElementById('cardScroll').scrollTop = Number(sessionStorage.getItem('scrollTop'))
  },
  deactivated(){
    let scrollTop = document.getElementById('cardScroll').scrollTop
    sessionStorage.setItem('scrollTop',scrollTop)
    console.log("名片信息:",document.getElementById('cardScroll').scrollTop);
  },
  components: {
    // caseItem,
    recommendItem,
  },
  setup() {
    onUnmounted(() => {
      state.playOrPause = true;
      // playOrPauseVideo();
    });

    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const router = useRouter();
    const route = useRoute();
    const handleReturn = () => {
      if (route.params.fromPoster){
        // router.push({ path: "/posterDetails" })
        router.back()
      }else {
        router.push({ path: "/" });
      }
    };
    let showHeader = computed(() => route.query.hideTitleBar != 1);
    let headImg = computed(() => {
      if (state.cardInfo.headImgUrl) {
        return `${process.env.VUE_APP_WATCH_FILE_API}/${state.cardInfo.headImgUrl}`;
      } else {
        return require('../../../assets/images/default-headImg.gif');
      }
    });
    let impressionLabel = computed(() => {
      if (state.cardInfo.impressionLabel) {
        return state.cardInfo.impressionLabel.split(",");
      } else {
        return null;
      }
    });
    const playvideo = () => {
      // let video = document.querySelectorAll("video")[0];
      // state.iconPlayShow = false;
      // video.play();
      // state.playOrPause = true;
      router.push(`/videoPlay/${state.cardInfo.cardId}`);
    };
    const playOrPauseVideo = () => {
      //暂停\播放
      // let video = document.querySelectorAll("video")[0];
      // if (state.playOrPause) {
      //   video.pause();
      //   state.iconPlayShow = true;
      // } else {
      //   video.play();
      //   state.iconPlayShow = video.paused ? true : false;
      // }
      // state.playOrPause = !state.playOrPause;
      router.push(`/videoPlay/${state.cardInfo.cardId}`);
    };
    const loadUserCardInfo = async () => {
      try {
        Toast.loading({
          forbidClick: true,
          message: "加载中",
        });
        let data = await getUserCard();
          console.log('个人信息',data);
        if (data) {
          state.cardInfo = data;
          state.cardInfo.name = data.createId?data.name:data.defaultName
          state.cardInfo.impressionLabel = data.createId?data.impressionLabel:"专业,热情"
          state.websiteBriefVO = data.websiteBriefVO;
          state.compnayLogo = data.websiteBriefVO.logoUrl?`${process.env.VUE_APP_WATCH_FILE_API}/${data.websiteBriefVO.logoUrl}`:state.defaultLogo
          state.isGif = (data.headImgUrl.indexOf('.gif')!==-1)
          state.userId = data.createId
          let res = await getRecommend({loginUserId:data.createId})
          state.myRecommendList = res.records
          if(res.total === state.myRecommendList.length){
            state.showMoreRecommend = false
          }
          console.log("state.myRecommendList====>",state.myRecommendList);
          state.isGif = (data.headImgUrl.indexOf('.gif')!==-1)
        }

        let video = await searchFileList({
          busiId: state.cardInfo.cardId,
          busiCat: "user",
          groupId: "descVideo",
        });
        console.log("video=========", video);
        state.loading = false;
        if (video.length) {
          console.log("video", video);
          state.videoUrl = `${process.env.VUE_APP_WATCH_FILE_API}/${video[0].url}`;
        }
        let userPics = await searchFileList({
          busiId: state.cardInfo.cardId,
          busiCat: "user",
          groupId: "userPics",
        });
        if (userPics.length) {
          state.userPics = userPics.map((item) => {
            item.url = `${process.env.VUE_APP_WATCH_FILE_API}/${item.url}`;
            return item;
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        Toast.clear();
      }
    };
    loadUserCardInfo();

    const loadMoreRecommend = async()=>{
      state.current =  state.current+1
      let itemList = await getRecommend({loginUserId:state.userId,current:state.current})
        state.myRecommendList.push(...itemList.records)
      if(state.myRecommendList.length === itemList.total){
        state.showMoreRecommend = false
      }
      console.log("而更多推荐：",itemList);
    }
    const state = reactive({
      showMoreRecommend:true,
      current:1,
      userId:'',
      defaultInfo:'我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！',
      websiteBriefVO: {},
      compnayLogo:'',
      cardInfo: {},
      showOverlay: false,
      defaultLogo: require("../../../assets/images/company-logo-default.png"),
      showShare: false,
      iconPlayShow: true,
      playOrPause: false,
      loading: true,
      videoUrl: "",
      userPics: [],
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "二维码", icon: require("@/assets/icons/erCode.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
      ],
      imgSrc: "",
      showImg: true,
      myRecommendList:[],
      isGif:false,
    });
    //选择分享菜单
    const onSelectShare = (option) => {
      if(option.name==='二维码'){
        handleLinkToQrcode()
        state.showShare = false;
      }else if(option.name==='复制链接'){
        getUserCardUrl().then((res)=>{
          copyStr(res)
          state.showShare = false
        })
      }else {
        let shareType;
        let content;
        let merchantId = localStorage.getItem('merchantId');
        addForwardLog(4).then((res)=>{
          console.log("分享日志：",res);
        })
        if (option.name === "微信") {
          shareType = "shareToMiniProgram";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/wxShareUserCard/1/${merchantId}`;
        } else if (option.name === "朋友圈") {
          shareType = "shareH5PageToTimeLine";
          content = `${process.env.VUE_APP_WECHAT_SHARE}/wxShareUserCard/2/${merchantId}`;
        }
        let title = state.cardInfo.name
            ? `您好，我是${state.cardInfo.name}，期待为您服务!`
            : "个人名片";
        let desc =state.cardInfo.selfIntro
            ? `${state.cardInfo.selfIntro}`
            : "自我介绍";
        let param = {
          miniProgramType:state.cardInfo.jumpApplets,
          shareType: shareType,
          urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
          },
          title: title,
          desc: desc,
          shareImg: (state.cardInfo.headImgUrl&&state.cardInfo.smallHeadImgUrl&&!state.isGif)?`${process.env.VUE_APP_WATCH_FILE_API}/${state.cardInfo.smallHeadImgUrl}`:"https://wca.ujiaku.com/upload/wx/wx_delivery_info.png",
        };
        flutterShowToast(param);
        state.showShare = false;
      }
    };
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };
    const handleLinkToQrcode = () => {
      state.showOverlay = true;
      nextTick(() => {
        console.log("imageWrapper", imageWrapper.value);
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          useCORS: true,
          background: "#ffffff",
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false;
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
          });
        });
      });

      // router.push(`/showUserCard`);
    };
    const handleLinkToModifyUserCard = () => {
      router.push(`/modifyUserCard/${state.cardInfo.cardId}`);
    };
    const handleLinkToOfficialWebsite = () => {
      if(state.cardInfo.isInitWebsite){
        if (state.cardInfo.isSetWebsite) {
        if (state.cardInfo.officialWebsite) {
          let url =
            state.cardInfo.officialWebsite.indexOf("https") === -1
              ? `https://${state.cardInfo.officialWebsite}`
              : state.cardInfo.officialWebsite;
          // window.location.href = url;
          let param = {
            shareType: "openExternalPage",
            urlEntity: {
              content: url,
            },
            title: "企业官网",
          };
          flutterShowToast(param);
        } else {
          Dialog.alert({
            title: "提示",
            message: "请先前往优家宝web进行官网设置。",
          });
        }
      } else {
        router.push({path:'/websiteVisitors',query:{hideTitleBar:route.query.hideTitleBar}})
      }
      }else{
         Dialog.alert({
            title: "提示",
            message: "请先前往优家宝web进行官网设置。",
          });
      }

    };
    const handleShareToWx = () => {
      state.showShare = true;
    };
    const toSavePic = ()=>{
      let params = {
        shareType:"saveBase64Image",
        desc:state.imgSrc
      }
      flutterShowToast(params)
      console.log(params)
    }
    const handleCancelSave = () =>{
      state.showOverlay = false
    }

    return {
      ...toRefs(state),
      toSavePic,
      handleCancelSave,
      loadMoreRecommend,
      handleReturn,
      handleLinkToModifyUserCard,
      handleLinkToOfficialWebsite,
      handleShareToWx,
      notfound,
      headImg,
      impressionLabel,
      handleLinkToQrcode,
      showHeader,
      onSelectShare,
      playOrPauseVideo,
      playvideo,
      imageWrapper,
      cardImgRef,
    };
  },
};
</script>
<style lang="scss">
.userCard {
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
}
</style>
<style lang="scss" scoped>
.userCard {
  text-align: left;
  margin-bottom: 50px;
  .case {
    padding: 8px 16px 0 16px;
  }
  .more-recomond{
    height: 35px;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    background-color: #E5E5E5;
    color: #9999;
    border-color: none;
  }
  .recommend {
    border-top: 2px solid #f1f3f6;
    padding: 8px 16px;
    img {
      vertical-align: middle;
      width: 100%;
      border-radius: 8px;
    }
  }
  .van-overlay {
    background-color: rgba(0, 0, 0, 1);
  }
  .head-title {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
  }
  .header-btn-right {
    color: #ff6e01;
  }
  .personal-info {
    height: 180px;
    word-break: break-all;
    margin: 16px;
    box-sizing: border-box;
    box-shadow: 5px 10px 10px #3333;
    padding-bottom: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    box-sizing: content-box;
    min-width: 64px;
    display: flex;
    margin-bottom: 3px;
    padding: 0px;
    border-radius: 5px;
    position: relative;
    .head-image {
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      img {
        width: 145px;
        height: 100%;
        object-fit: cover;
        vertical-align: bottom;
        align-items: flex-end;
        border-radius: 4px;
        border-right: 15px solid transparent;
      }
    }
    .info {
      text-align: left;
      flex: 1;
      img {
        position: absolute;
        left: 130px;
        width: 210px;
        height: 100%;
      }
      .des {
        color: #666666;
        font-weight: 600;
        font-size: 11px;
      }
      .z-big {
        z-index: 99;
      }
      .name {
        font-size: 24px;
        color: #333333;
        margin-top: 37px;
        font-weight: 600;
      }
      .title {
        margin-top: 4px;
      }
      .phone {
        margin-top: 16px;
      }
      .role {
        font-weight: 400;
        margin-top: 8px;
      }
      .company {
        margin-top: 4px;
        font-weight: 600;
      }
    }
    .icon-right {
      align-self: flex-end;
      color: #289fff;
    }
  }
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
  }
  .description {
    border-top: 3px solid #f1f3f6;
    position: relative;
    border-bottom: 3px solid #f1f3f6;
    margin-top: 15px;
    padding: 16px;
    video {
      width: 100%;
      height: 200px;
    }
    .icon_play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 60px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
    }
    .content {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }
  .user-tags {
    padding: 16px;
    border-bottom: 3px solid #f1f3f6;
    .tag-item {
      margin-right: 3px;
      .van-tag {
        padding: 3px 8px;
      }
    }
  }
  .company-desc {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    color: #666666;
    // height: 24px;
    padding: 8px 0;
    border-bottom: 3px solid #f1f3f6;
    padding: 16px;
    span {
      display: inline-block;
      // color: #289FFF;
    }
    span:nth-child(2) {
      color: #289fff;
    }
    .company-desc-top {
      display: flex;
      justify-content: space-between;
    }
    .company-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .compnay-btn {
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      font-size: 12px;
    }
    .company-info {
      // height: 41px;
      background-color: #fff;
      padding: 8px 0;
      display: flex;
      align-items: center;
      > img {
        height: 40px;
        width: 40px;
        border-radius: 20px;
      }
      .company-info-describe {
        margin-left: 8px;
        > p {
          margin: 0;
          padding: 0;
        }
        > :nth-child(1) {
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
    .company-introduce {
      font-weight: 400;
      font-size: 14px;
      color: #6666;
      background-color: #fff;
    }
  }
  .cases {
    padding: 16px;
    border-bottom: 3px solid #f1f3f6;
    .case-item {
      img {
        width: 100%;
        height: 160px;
      }
      .case-title {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
      }
      .other-info {
        font-weight: 600;
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .bottom-btns {
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    button {
      height: 50px;
      color: #fff;
      box-sizing: border-box;
    }
    button:nth-child(1) {
      background-color: #fc9834;
    }
    button:nth-child(2) {
      background-color: #ff6e01;
    }
    .van-button--block{
      border-radius: 4px;
    }
  }
  .wrapper {
    height: 100vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 26px;
    text-align: center;
    .content-box {
      background-color: #ffffff;
      border-radius: 5px;
      min-height: 400px;
      .head-box {
        flex-basis: 25%;
        margin: 0px 16px;
        padding: 14px 0px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f1f3f6;
        img {
          height: 64px;
          width: 64px;
          border-radius: 50%;
        }
        .polygon {
          display: inline-block;
          width: 0px;
          height: 0px;
          border: 15px solid #289fff;
          border-left: 8px solid transparent;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
        }
        .msg {
          display: inline-block;
          background: #289fff;
          padding: 11px 16px 11px 28px;
          border-radius: 5px;
          flex: 1;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-align: left;
        }
      }
      .body-box {
        .user-name {
          color: #333333;
          text-align: center;
          font-weight: 600;
          font-size: 22px;
          margin: 20px 0px 6px 0px;
        }
        .merchant-name {
          color: #bfbfbf;
          font-weight: 600;
          font-size: 12px;
        }
        .qr-code {
          width: 158px;
          height: 158px;
          margin: 20px;
        }
        .btm-msg {
          margin-bottom: 20px;
          color: #bfbfbf;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .card-img {
    }
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
//桌面端适配
import '@vant/touch-emulator';
//解决适配问题
import 'amfe-flexible/index.js'
//去除谷歌浏览器对阻止touch事件默认行为的警告提示
import 'default-passive-events';
import '@/assets/iconfont'
//引入vant组件库
import Vant from 'vant';
import 'vant/lib/index.css';
import iconSvg from '@/components/IconSvg'

import { flutterShowToast } from '@/utils/appChannel'

const whiteList = ['/login', '/shareForward']
router.beforeEach((to, from, next) => {
    console.log('to==========', to)
    console.log('from==========', from)
    if (to.path === '/') {
        let param = {
            shareType: "backIndexPage",
            urlEntity: {
                content: `${process.env.VUE_APP_WECHAT_SHARE}`,
            },
        };
        flutterShowToast(param);
    }
    if (to.query && to.query.appPhone) {
        store.dispatch('getAllTokens', { appPhone: to.query.appPhone }).then(() => {
            next()
        })
    } else {
        if (store.getters.refresh_token) {
            if (to.path === '/login') {
                next({ path: '/' });
            } else {
                next()
            }
        } else {
            if (whiteList.indexOf(to.path) !== -1) {
                next()
            } else {
                next('/login');
            }
        }
    }

})


const app = createApp(App)
app.use(store).use(router).use(Vant).mount('#app')
app.component('icon-svg', iconSvg)

<template>
  <div class="home">
    <van-sticky>
      <van-nav-bar safe-area-inset-top>
        <template #title>
          <div class="home-tile">躺赢</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="dash-swipe">
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item
          v-for="(item, index) in carousels"
          :key="index"
          @click="handleSwipeItemClick(item)"
        >
          <img :src="item.picUrl" style="object-fit: cover;" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="menu-list">
      <van-grid :column-num="4" :border="false">
        <van-grid-item
          v-for="(item, index) in menuList"
          :key="index"
          icon="photo-o"
          @click="handleLinkToPages(item.router)"
        >
          <div class="menu-item">
            <img
              class="icon-mune"
              :src="require(`@/assets/menuPics/${item.router}.png`)"
            />
            <div>{{ item.menuName }}</div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="recommend">
      <img
        v-for="item in recommendContents"
        :key="item"
        :src="item.picUrl"
        style="object-fit: cover;"
        @click="handleSwipeItemClick(item)"
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { flutterShowToast } from "@/utils/appChannel";
// import { useBrowseCount } from "@/hooks/browseCount";
import { loadDashBoradInfo } from "@/api/user";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
export default {
  name: "Home",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      upload: null,
      carousels: [],
      menuList: [],
      recommendContents: [],
    });
    const getDashBoradInfo = async () => {
      try {
        Toast.loading({
          forbidClick: true,
          message: "加载中",
        });
        let data = await loadDashBoradInfo();
        console.log(data);
        state.menuList = data.menus;
        state.carousels = data.carousels.map((item) => {
          item.picUrl = `${process.env.VUE_APP_WATCH_FILE_API}/${item.picUrl}`;
          return item;
        });
        state.recommendContents = data.recommendContents.map((item) => {
          item.picUrl = `${process.env.VUE_APP_WATCH_FILE_API}/${item.picUrl}`;
          return item;
        });
        console.log(state.recommendContents);
        Toast.clear();
      } catch (error) {
        Toast.clear();
        console.error(error);
      }
    };
    const handleLinkToPages = (path) => {
      if (route.query && route.query.isApp == 1) {
        let param = {
          shareType: "openNewPage",
          urlEntity: {
            content: `${process.env.VUE_APP_WECHAT_SHARE}/${path}`,
          },
        };
        flutterShowToast(param);
      } else {
        router.push(path);
      }
    };
    getDashBoradInfo();
    const handleSwipeItemClick = (item) => {
      if (item.redirectUrl) {
        let redirectUrl = item.redirectUrl.indexOf("?") == -1?item.redirectUrl + "?from=home": item.redirectUrl + "&from=home"
        if (route.query && route.query.isApp == 1) {
          let param = {
            shareType: "openExternalPage",
            urlEntity: {
              content: redirectUrl,
            },
            title: '',
          };
          flutterShowToast(param);
        } else {
          window.location.href = redirectUrl;
        }
      }
    };
    return {
      handleLinkToPages,
      handleSwipeItemClick,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss">
.home {
  .van-grid-item__content {
    padding: 0;
  }
}

.dash-swipe {
  .van-swipe-item {
    color: #fff;
    text-align: center;
    img {
      width: 100%;
      height: 160px;
      border-radius: 8px;
    }
  }
  .van-swipe__indicators {
    left: 90%;
  }
  .van-swipe__indicator {
    width: 5px;
    height: 2px;
  }
}
</style>
<style lang="scss" scoped>
.test {
  color: red;
  font-size: 20px;
  height: 40px;
  width: 40px;
  font-weight: bold;
  background-color: transparent;
}
.home {
  background: #ffffff;
  .dash-swipe {
    padding: 0px 16px;
    height: 160px;
  }
  .home-tile {
    padding: 0px 16px;
    font-weight: bold;
    font-size: 18px;
    color: #000;
  }
  .title {
    width: 100%;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin: 8px auto;
  }
  .menu-list {
    .menu-item {
      margin-top: 24px;
      .icon-mune {
        width: 28px;
        height: 28px;
      }
      div {
        height: 20px;
        line-height: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
      }
    }
  }
  .recommend {
    padding: 8px 16px;
    img {
      vertical-align: middle;
      width: 100%;
      border-radius: 8px;
    }
  }
}
</style>

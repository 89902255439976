import { post,get } from '@/utils/request';
/**
 * 获取公费电话详情
 * @param params
 */
export function test(param) {
  return post(
    '/user-center/test',
    param,
  );
}

/**
 * 注销（删除session和token）
 */
export function logout() {
  return post(
    '/user/logout',
  );
}

/**
 * 用户名密码获取token,刷新token
 * @param {} param 
 */
export function oauth(param){
  return post(
    '/user/oauth/user/token',
    param,
    false
  )
}
/**
 * 
 * @param param 
 */
export function wxLogin(param){
  return get(
    '/user/wx/login',
    param,
    false
  )
}

/**
 * 首页接口
 * @param {} param 
 */
export function loadDashBoradInfo(param){
  return get(
    '/user/index',
    param,
  )
}

/**
 * 微信公众号获取个人信息
 * @param {*} param 
 */
export function getPublicInfo(param){
  return get(
    `/user/wx/getPublicInfo?code=${param}`,
  )
}
/**
 * 获取优家宝的物料详情
 * @param {*}
 */
export function getMaterial(materialId){
  return get(
    `/user/saas/getMaterial/${materialId}`,
  )
}
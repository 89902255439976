const app = {
    state: {
        loading: false
    },
    mutations: {
        showLoading: (state) => {
            state.loading = true
        },
        hideLoading: (state) => {
            state.loading = false
        }
    },
    actions: {}
}

export default app;

/**
 * 与app通信function
 * @param {*} msg 
 * 
 * /**
  * 分享类型:
  *  分享到小程序：shareToMiniProgram
  *  分享到微信对话中：shareH5PageToDialog
  *  分享到朋友圈：shareH5PageToTimeLine
  *  通知打开页面：openNewPage
  *  通知回到首页：backIndexPage
  *  通知修改背景颜色：backgroundColor
  *  踢出登录： "logout"
 String shareType;//必须
    Object url;//进入的路径
    urlEntity:{
        title:'http://tangying.ujiaku.com/shareRedirect?'
        content:'http://?appPhone=',
    }
 String title;//标题
 String desc;//描述
 String shareImg;//分享时显示在封面的图片，可以不传
 
 backgroundColorEntity:{
    int r;
    int g;
    int b;
    double opacity;
 } 
 * 
 * 
 */
export function flutterShowToast(msg) {
    let p = JSON.stringify(msg)
    console.log(p)
    try {
        // eslint-disable-next-line no-undef
        flutterToast.postMessage(p);
    } catch (error) {
        console.log('error=====', error)
    }


}



import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import qs from 'qs'

import userCard from '../views/card/userCard/index.vue'
import NotFound from '../views/notFound'
const routes = [
  { path: '/:catchAll(.*)*', name: 'NotFound', component: NotFound },
  { path: '/', name: 'Home', component: Home },
  { path: '/login', name: 'login', component: () => import('../views/Login.vue') },
  { path: '/about', name: 'About', component: () => import(/* webpackChunkName: "about" */ '../views/About.vue') },
    //获客视频(视频列表,也包括我的视频列表)
  { path: '/videoVisitors', name: 'video', component: () => import('../views/video/videoList.vue')},
    //搜索视频
  { path: '/video/searchVideo', name: "searchVideo", component: () => import('../views/video/searchVideo.vue')},
    //视频详情
  { path: '/video/videoDetail/:vdId/:vusrId?/:clueShareSource?/:merchantId?/:recommendClueId?', name: "videoDetail", component: () => import('../views/video/videoDetail.vue')},
    //添加视频
  { path: '/video/createVideo', name: "createVideo", component: () => import('../views/video/createVideo.vue')},
  { path: '/video/selectCreateType', name: "selectCreateVideoType", component: () => import('../views/video/selectCreateType.vue')},
  { path: '/video/reprintVideo', name: "reprintVideo", component: () => import('../views/video/reprintVideo.vue')},
  //编辑视频
  { path: '/video/editVideo/:vdId', name: "editVideo", component: () => import('../views/video/editVideo.vue')},
  { path: '/shareTest', name: 'shareTest', component: () => import(/* webpackChunkName: "about" */ '../views/shareTest.vue') },
  { path: '/test', name: 'test', component: () => import(/* webpackChunkName: "about" */ '../views/Test.vue') },
  { path: '/shareForward', name: 'shareForward', component: () => import(/* webpackChunkName: "about" */ '../views/shareRedirect.vue') },

  //文章管理
  { path: '/articleManagement', name: 'articleManagement', component: () => import(/* webpackChunkName: "articleManagement" */'../views/articleManagement/articleManagement.vue') },
  { path: '/articleManagement/articleDetails', name: 'articleDetails', component: () => import('../views/articleManagement/articleDetails.vue') },
  { path: '/articleManagement/reviewed', name: 'reviewed', component: () => import('../views/articleManagement/articleReviewed.vue') },
  { path: '/articleManagement/search', name: 'managementSearch', component: () => import('../views/articleManagement/searchArticle.vue') },

  //获克文章
  { path: '/articleVisitors', name: 'articleVisitors', component: () => import('../views/huokeArticles/huokeArticles.vue')},
  { path: '/articleVisitors/createArticle', name: 'createArticle', component: () => import('../views/huokeArticles/createArticle.vue')},
  { path: '/articleVisitors/search', name: 'visitorsSearch', component: () => import('../views/articleManagement/searchArticle.vue')},
  { path: '/articleVisitors/visitorDetails', name: 'visitorDetails', component: () => import('../views/huokeArticles/visitorsArticlesDetails.vue')},
  { path: '/articleVisitors/visitorEditor', name: 'visitorEditor', component: () => import('../views/huokeArticles/visitorsAtriclesEditor.vue')},
  { path: '/articleVisitors/reprintArticle', name: 'visitorReprint', component: () => import('../views/huokeArticles/reprintArticle.vue')},//转载文章
  { path: '/articleVisitors/selectCreateType', name: 'selectCreateType', component: () => import('../views/huokeArticles/selectCreateType.vue')},//选择转载文章类型

  //微信分享
  { path: '/weChatShare/:id/:clueShareSource?/:merchantId?/:recommendClueId?', name: 'weChatShare', component: () => import('../views/weChatShare/weChatShare.vue') },
  { path: '/caseShare/:id/:clueShareSource?/:merchantId?/:recommendClueId?', name: 'caseShare', component: () => import('../views/weChatShare/caseShare.vue') },
  { path: '/materialShare/:id/:clueShareSource?/:merchantId?/:recommendClueId?', name: 'materialShare', component: () => import('../views/weChatShare/materialShare.vue') },

  //案例
  { path: '/caseVisitors', name: 'caseIndex', component: () => import('../views/case/caseIndex.vue') },
  { path: '/caseVisitors/caseDetails', name: 'caseDetails', component: () => import('../views/case/caseDetails.vue') },
  { path: '/caseVisitors/caseEditor', name: 'caseEditor', component: () => import('../views/case/caseEditor.vue') },
  { path: '/caseVisitors/caseSearch', name: 'caseSearch', component: () => import('../views/case/caseSearch.vue') },
  //全景图
  { path: '/panoramicWindow', name: 'panoramicWindow', component: () => import('../views/weChatShare/panoramicWindow.vue') },
  //名片
  { path: '/userCard', name: 'userCard', component: userCard },
  { path: '/modifyUserCard/:id', name: 'modifyUserCard', component: () => import('../views/card/userCard/modifyUserCard') },
  { path: '/showUserCard', name: 'showUserCard', component: () => import('../views/card/userCard/showUserCard') },
  { path: '/wxShareUserCard/:clueShareSource?/:merchantId?/:recommendClueId?', name: 'wxShareUserCard', component: () => import('../views/card/userCard/wxShareUserCard') },
  { path: '/videoPlay/:cardId', name: 'videoPlay', component: () => import('../views/card/userCard/videoPlay') },
  //微官网
  { path: '/websiteVisitors', name: 'websiteVisitors',component: () => import('../views/microWebsite/websiteIndex.vue')},
  { path: '/microWebsiteShare/:clueShareSource?/:merchantId?/:recommendClueId?', name: 'websiteShare', component: () => import('../views/microWebsite/microWebsiteShare.vue') },
  //海报中心
  { path: '/posterVisitors', name: 'posterVisitors',component: () => import('../views/posterCenter/index.vue')},
  { path: '/createPoster', name: 'createPoster',component: () => import('../views/posterCenter/createPoster')},
  { path: '/posterDetails', name: 'posterDetails',component: () => import('../views/posterCenter/detail')},
  { path: '/wxPosterIndex/:id/:clueShareSource?/:merchantId?', name: 'wxPosterIndex', component: () => import('../views/posterCenter/wxPosterIndex') },
  //工地中心
  // { path: '/buildVisitors', name: 'buildVisitors', component: () => import('../views/buildSite/list') },
  // { path: '/buildSite/details', name: 'buildSiteDetails', component: () => import('../views/buildSite/details') },
  // { path: '/buildSite/editor', name: 'buildSiteEditor', component: () => import('../views/buildSite/editor') },
  // { path: '/buildSite/search', name: 'buildSiteSearch', component: () => import('../views/buildSite/search') },
  // { path: '/buildSiteShare/:id/:clueShareSource?/:merchantId/:recommendClueId?', name: 'buildSiteShare', component: () => import('../views/buildSite/share') },
  //活动
  { path: '/activityVisitors', name: 'activityVisitors', component: () => import('../views/activity/list') },
  { path: '/activity/details', name: 'activityDetails', component: () => import('../views/activity/details') },
  { path: '/activity/search', name: 'activitySearch', component: () => import('../views/activity/search') },
  { path: '/activityShare/:id/:clueShareSource?/:merchantId?/:recommendClueId?', name: 'activityShare', component: () => import('../views/activity/share') },
  //计算器
  { path: '/fitupCalculator', name: 'fitupCalculator',component: () => import('../views/fitupCalculator/index.vue')},
  { path: '/wxShareCalculator/:clueShareSource?/:merchantId?/:recommendClueId?', name: 'wxShareCalculator', component: () => import('../views/fitupCalculator/wxShareCalculator.vue') },
  //新的工地中心
  { path: '/buildVisitors', name: 'buildVisitors', component: () => import('../views/newBuildSite/building') },
  { path: '/siteDetail', name: 'siteDetail', component: () => import('../views/newBuildSite/siteDetail.vue') },
  { path: '/buildSiteShare/:id/:clueShareSource?/:merchantId/:recommendClueId?', name: 'buildSiteShare', component: () => import('../views/newBuildSite/shareBuildSite.vue') },
  { path: '/searchBuildSite', name: 'searchBuildSite', component: () => import('../views/newBuildSite/searchBuildSite') },
]

const router = createRouter({
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

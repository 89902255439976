import { createStore } from 'vuex'
import user from './modules/user'
import permission from './modules/permission'
import app from './modules/app'
import video from './modules/video'
import getters from './getters'

export default createStore({
  getters:{
    ...getters
  },
  modules: {
    user,
    permission,
    app,
    video
  }
})

import axios from 'axios'
import store from '../store'
import { Dialog } from 'vant';
import { flutterShowToast } from '@/utils/appChannel'
const TOKEN_KEY = 'access_token'
const getToken = () => localStorage.getItem(TOKEN_KEY)
const setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token)
}
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL:'http://192.168.188.104:8100',  //测试接口
    timeout: 10000
})

let isRefreshing = false // 标记是否正在刷新 token， 防止多次刷新token
let requests = [] // 存储待重发请求的数组(同时发起多个请求的处理)

// request拦截器
service.interceptors.request.use(config => {
    // store.commit('showLoading')
    return config
}, error => {
    // store.commit('hideLoading')
    Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
    response => {
        // store.commit('hideLoading')
        const res = response.data;
        if (res.code === 200) {
            return Promise.resolve(res.response);
        } else if (res.code === 1002) {
            store.dispatch('FedLogOut')
        } else if (res.code === 1001) {
            const { config } = response
            if (!isRefreshing) {
                isRefreshing = true
                return store.dispatch('refreshToken').then(res => {
                    const { access_token } = res
                    setToken(access_token)
                    config.headers.Authorization = `Bearer ${access_token}`
                    // token 刷新后将数组的方法重新执行
                    requests.forEach((cb) => cb(access_token))
                    requests = [] // 重新请求完清空
                    return service(config)
                }).catch(err => {
                    console.log('抱歉，您的登录状态已失效，请重新登录！')
                    return Promise.reject(err)
                }).finally(() => {
                    isRefreshing = false
                })
            } else {
                // 返回未执行 resolve 的 Promise
                return new Promise(resolve => {
                    // 用函数形式将 resolve 存入，等待刷新后再执行
                    requests.push(token => {
                        config.headers.Authorization = `Bearer ${token}`
                        resolve(service(config))
                    })
                })
            }
        }
        else if (res.code === 500) {
            Dialog.alert({
                title: '错误',
                message: res.message,
            });
           return Promise.reject()
        } else if (res.code === 700) {
            Dialog.alert({
                message: '授权已过期,请退出重新登录'
            }).then(() => {
                //通知app退出重新登录
                flutterShowToast({ shareType: 'logout' })
            });
        }
    },
    error => {
        // Dialog.alert({
        //     title: '错误',
        //     message: '当前服务不可用,请稍后尝试',
        // });
        // store.commit('hideLoading')
        return Promise.reject(error);
    }
)

// 给请求头添加 access_token
const setHeaderToken = (isNeedToken) => {
    const accessToken = isNeedToken ? getToken() : null
    if (isNeedToken) { // api 请求需要携带 access_token 
        if (!accessToken) {
            console.log('不存在 access_token 则跳转回登录页')
        }
        service.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        delete service.defaults.headers.common.Authorization
    }
}

// 有些 api 并不需要用户授权使用，则无需携带 access_token；默认不携带，需要传则设置第三个参数为 true
export const get = (url, params = {}, isNeedToken = true) => {
    setHeaderToken(isNeedToken)
    return service({
        method: 'get',
        url,
        params,
    })
}

export const post = (url, params = {}, isNeedToken = true, config = {}) => {
    setHeaderToken(isNeedToken)
    return service({
        method: 'post',
        url,
        data: params,
        ...config
    })
}
export const put = (url, params = {}, isNeedToken = true, config = {}) => {
    setHeaderToken(isNeedToken)
    return service({
        method: 'put',
        url,
        data: params,
        ...config
    })
}
export const del = (url, params = {}, isNeedToken = true) => {
    setHeaderToken(isNeedToken)
    return service({
        method: 'delete',
        url,
        params,
    })
}


export const uploadPost = (url, param) => {
    setHeaderToken(true)
    return service({
        url: url,
        baseURL: process.env.VUE_APP_FILE_API,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
        data: param
    })
}
<template>
  <div class="cases" @click="handleViewCase(recommendInfo)">
    <div class="case-item">
      <div class="recommend-type">{{typeText}}</div>
      <div :class="recommendInfo.contentType===4?'allcover-video':''">
         <img :src="coverImg" :class="recommendInfo.contentType===4?'videocover':'othercover'"/>
      </div>
      <div class="case-foot">
        <div style="flex:1">
          <div class="case-title">{{recommendInfo.title}}</div>
          <div class="other-info" v-if="recommendInfo.contentType===3">
           {{recommendInfo.caseTypeName}} | {{recommendInfo.caseArea}}m² |
            {{ recommendInfo.casePosition }}
          </div>
          <div class="other-info" v-if="recommendInfo.contentType===5">
            {{recommendInfo.buildTypeName}} | {{recommendInfo.buildArea}}m² |
            {{ recommendInfo.buildPosition }}
          </div>
           <span class="case-type" v-if="recommendInfo.contentType!==3&&recommendInfo.contentType!==5&&recommendInfo.categoryName">{{recommendInfo.categoryName}}</span>
        </div>
        <div
          class="transh-box"
          v-if="showDel"
          @click.stop="emitDelete(recommendInfo)"
        >
          <icon-svg class="delete-icon" iconClass="icontrash-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notfound from "@/assets/images/case-defaultimg.png";
import articleNotfound from "../assets/images/article-defaultImg-big.png"
import videoNotfound from "../assets/images/videoListDefaultCover.jpg"
import buildNotfound from "../assets/images/buildSite-default.png"
// /img/jpg/videoListDefaultCover.jpg
import trashPng from "@/assets/icons/trash-2.png";
import { computed, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    showDel: {
      type: Boolean,
      default: false,
    },
    recommendInfo: {
      type: Object,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      typeText:props.recommendInfo.contentType === 1?'文章':props.recommendInfo.contentType === 3?'案例':props.recommendInfo.contentType === 4?'视频':'工地'
    })
    const emitDelete = (item) => {
      emit("deletItem", item);
    };
    const handleViewCase = (item) => {
// 内容分类 1-文章 3-案例 4-视频-5工地
      if (!props.isShared) {
        console.log('推荐内容',item.recommendId);
        if(props.recommendInfo.contentType===1){
          router.push({
          path: "/articleVisitors/visitorDetails",
          query: { id: props.recommendInfo.recommendId,from:'card'},
        });
        }else if(props.recommendInfo.contentType===3){
           router.push({
          path: "/caseVisitors/caseDetails",
          query: { caseId: props.recommendInfo.recommendId,from:'card'},
        });
        }else if(props.recommendInfo.contentType===4){
          // router.push({ path: `/video/videoDetail/${props.recommendInfo.recommendId}` });
          router.push({ name: 'videoDetail',params:{vdId:props.recommendInfo.recommendId},query:{from:'card'} });
        }else if(props.recommendInfo.contentType===5){
          router.push({
            path: "/buildSite/details",
            query: { buildId: props.recommendInfo.recommendId,from:'card'},
          });
        }
      } else {
        emit("viewRecommend");
      }
    };
    let coverImg = computed(() => {
      if (props.recommendInfo.contentType===1) {
        if(props.recommendInfo.indexImgUrl){
          return `${process.env.VUE_APP_WATCH_FILE_API}/${props.recommendInfo.indexImgUrl}`;
        }else{
          return articleNotfound
        }
      } else if(props.recommendInfo.contentType===3){
        if(props.recommendInfo.indexImgUrl){
          return `${process.env.VUE_APP_WATCH_FILE_API}/${props.recommendInfo.indexImgUrl}`;
        }else{
          return notfound
        }
      }else if(props.recommendInfo.contentType===4){
       if(props.recommendInfo.indexImgUrl){
          return `${process.env.VUE_APP_WATCH_FILE_API}/${props.recommendInfo.indexImgUrl}`;
        }else{
          return videoNotfound
        }
      }else if(props.recommendInfo.contentType===5){
        if(props.recommendInfo.indexImgUrl){
          return `${process.env.VUE_APP_WATCH_FILE_API}/${props.recommendInfo.indexImgUrl}`;
        }else{
          return buildNotfound
        }
      }
    });
    return { ...toRefs(state),coverImg, trashPng, emitDelete, handleViewCase };
  },
};
</script>

<style lang="scss" scoped>
.cases {
  padding:8px 16px;
  border-bottom: 1px solid #f1f3f6;
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
  }
  .case-item {
    .recommend-type{
      margin: 10px;
      position: absolute;
      padding: 0 5px;
      font-size: 14px;
      color: #000000;
      border-radius: 4px;
      background-color: #fff;
    }
    .case-foot {
      display: flex;
      flex-direction: row;
      .transh-box {
        align-self: flex-end;
        .delete-icon {
          padding: 20ppx;
          width: 18px;
          height: 18px;
        }
      }
    }
    .allcover-video{
      background-color: black;
      height: 160px;
      text-align: center;
      img{
        margin: 0;
      }
    }
    .othercover{
      width: 100%;
      height: 160px;
      object-fit:cover;
    }
    .videocover{
      height: 160px;
      object-fit:cover;
    }
    // img {
    //   width: 100%;
    //   height: 160px;
    //   object-fit:cover;
    // }
    .case-title {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
    .other-info {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
    .case-type {
        margin: 0;
        padding: 3px 8px;
        // width: 70px;
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        color: #999999;
        border: 1px solid #999999;
    }
  }
}
</style>

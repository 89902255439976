import { post,uploadPost } from '@/utils/request';
export function searchFileList(param) {
    return post(
        `/file-server/searchFileList?busiId=${param.busiId}&busiCat=${param.busiCat}&groupId=${param.groupId}`,
        param
    );
}
export function deleteFile(param) {
    return post(
        `/file-server/deleteFile`,
        param
    );
}
export function uploadFile(param) {
    const busiId = param.get('busiId')
    const busiCat = param.get('busiCat')
    const groupId = param.get('groupId')
    return uploadPost(
        `/file-server/uploadFile?busiId=${busiId}&busiCat=${busiCat}&groupId=${groupId}&fileId=0`,
        param,
    )
}

export function uploadCoverPic(param) {
    const busiId = param.get('busiId')
    const busiCat = param.get('busiCat')
    const groupId = param.get('groupId')
    return uploadPost(
        `/file-server/uploadCoverPic?busiId=${busiId}&busiCat=${busiCat}&groupId=${groupId}`,
        param,
    )
}
/**
 * 查找各模块上传的海报图片
 * */
export function findPosterImg(param) {
    // return post(
    //     `/file-server/searchFileList?busiId=${busiId}&busiCat=poster&groupId=posterPic`,
    // );
    return post(
        `/file-server/searchFileList?busiId=${param.busiId}&busiCat=${param.busiCat}&groupId=${param.groupId}`,
        param
    );
}
import { logout, oauth, wxLogin } from '@/api/user'
import router from '@/router'

const user = {
    state: {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        merchantId:localStorage.getItem('merchantId')
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, access_token) => {
            state.access_token = access_token
            localStorage.setItem('access_token', access_token)
        },
        SET_REFRESH_TOKEN: (state, refresh_token) => {
            state.refresh_token = refresh_token
            localStorage.setItem('refresh_token', refresh_token)
        },
        SET_MERCHANT_ID:(state,merchantId)=>{
            state.merchantId = merchantId
            localStorage.setItem('merchantId',merchantId)
        }
    },
    actions: {
        //app端获取躺赢token
        getAllTokens({ commit }, payload) {
            return new Promise((resolve) => {
                let param = {
                    identity: payload.appPhone
                }
                wxLogin(param).then((response) => {
                    if (response) {
                        commit('SET_ACCESS_TOKEN', response.access_token);
                        commit('SET_REFRESH_TOKEN', response.refresh_token);
                        commit('SET_MERCHANT_ID',response.merchantId);
                        resolve({ access_token: response.access_token })
                    }
                })
            })
        },
        refreshToken({ commit, state }) {
            return new Promise((resolve) => {
                let param = {
                    clientId: 'system',
                    clientSecret: 'system',
                    scope: 'app',
                    grantType: 'refresh_token',
                    refreshToken: state.refresh_token
                }
                oauth(param).then((response) => {
                    if (response) {
                        commit('SET_ACCESS_TOKEN', response.access_token);
                        commit('SET_REFRESH_TOKEN', response.refresh_token);
                        resolve({ access_token: response.access_token })
                    }
                })
            })
        },
        // 掉接口退出登录
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.access_token).then(() => {
                    commit('SET_ACCESS_TOKEN', '');
                    commit('SET_REFRESH_TOKEN', '');
                    localStorage.clear();
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            });
        },

        // 前端登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_ACCESS_TOKEN', '');
                commit('SET_REFRESH_TOKEN', '');
                localStorage.clear();
                router.replace('/login')
                resolve();
            });
        }
    }
}

export default user;



const video = {
    state: {
        myVideoListTag: false,
    },
    mutations: {
        SET_MY_VIDEO_LIST_TAG: (state, myVideoListTag) => {
            state.myVideoListTag = myVideoListTag
        },
    }
}

export default video;
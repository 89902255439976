import {flutterShowToast} from "@/utils/appChannel";
import {Toast} from "vant";

/**
 * 通用型的工具
 * @param {*} msg
 */

function throttle(func, wait, otherFunc = null) {
    let previous = 0;
    return function () {
        let now = Date.now();
        let context = this;
        let args = arguments;
        if (now - previous > wait) {
            func.apply(context, args);
            previous = now;
        } else {
            otherFunc && otherFunc.apply(context, args)
        }
    }
}

export {throttle}

//案例分享
export function shareCaseToMiniProgram(caseItem, options) {
    let shareType;
    let content;
    let merchantId = localStorage.getItem('merchantId')
    if (options.name === "微信") {
        shareType = "shareToMiniProgram";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/caseShare/${caseItem.caseId}/1/${merchantId}`
    } else if (options.name === "朋友圈") {
        shareType = "shareH5PageToTimeLine";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/caseShare/${caseItem.caseId}/2/${merchantId}`
    }
    let param = {
        miniProgramType:caseItem.jumpApplets,
        shareType: shareType,
        urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
        },
        title: caseItem.title,
        shareImg: caseItem.headImgUrl ? `${process.env.VUE_APP_WATCH_FILE_API}/${caseItem.smallCoverPic}` : 'https://wca.ujiaku.com/upload/app_defaultImg/case-default.png'
    };
    console.log(param);
    flutterShowToast(param);
}
//工地分享
export function shareBuildToMiniProgram(buildItem, options) {
    let shareType;
    let content;
    let merchantId = localStorage.getItem('merchantId')
    if (options.name === "微信") {
        shareType = "shareToMiniProgram";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/buildSiteShare/${buildItem.buildId}/1/${merchantId}`
    } else if (options.name === "朋友圈") {
        shareType = "shareH5PageToTimeLine";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/buildSiteShare/${buildItem.buildId}/2/${merchantId}`
    }
    let param = {
        miniProgramType:buildItem.jumpApplets,
        shareType: shareType,
        urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
        },
        title:`${buildItem.title}已进入${buildItem.stageName??""}，围观了解施工注意事项！`,
        shareImg: buildItem.headImgUrl ? `${process.env.VUE_APP_WATCH_FILE_API}/${buildItem.headImgUrl}` : 'https://wca.ujiaku.com/upload/wx/buildSite_default.png'
    };
    console.log(param);
    flutterShowToast(param);
}
//活动分享
export function shareActivityToMiniProgram(buildItem, options) {

    console.log("微信分享-------------",buildItem)
    let shareType;
    let content;
    let merchantId = localStorage.getItem('merchantId')
    if (options.name === "微信") {
        shareType = "shareToMiniProgram";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/activityShare/${buildItem.activityId}/1/${merchantId}`
    } else if (options.name === "朋友圈") {
        shareType = "shareH5PageToTimeLine";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/activityShare/${buildItem.activityId}/2/${merchantId}`
    }
    let param = {
        miniProgramType:buildItem.jumpApplets,
        shareType: shareType,
        urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
        },
        title: buildItem.title,
        shareImg: buildItem.headImgUrl ? `${process.env.VUE_APP_WATCH_FILE_API}/${buildItem.smallCoverPic}` : 'https://wca.ujiaku.com/upload/wx/activity_default_img.jpg'
    };
    console.log(param);
    flutterShowToast(param);
}
//海报分享
export function sharePosterToMiniProgram(posterItem, options) {
    let shareType;
    let content;
    let merchantId = localStorage.getItem('merchantId')
    if (options.name === "微信") {
        shareType = "shareToMiniProgram";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/wxPosterIndex/${posterItem.posterId}/1/${merchantId}`
    } else if (options.name === "朋友圈") {
        shareType = "shareH5PageToTimeLine";
        content = `${process.env.VUE_APP_WECHAT_SHARE}/wxPosterIndex/${posterItem.posterId}/2/${merchantId}`
    }
    let param = {
        miniProgramType:posterItem.jumpApplets,
        shareType: shareType,
        urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
        },
        title: posterItem.posterName,
        shareImg:`${process.env.VUE_APP_WATCH_FILE_API}/${posterItem.smallPosterImgUrl}`
    };
    console.log(param);
    flutterShowToast(param);
}
//复制文本
export function copyStr(str) {
    let param = {
        shareType: "copyText",
        desc: str
    };
    flutterShowToast(param)
    console.log("复制连接",param)
    Toast.success("复制成功")
}
export function openExternalBrowser(url) {
    let param = {
        shareType: "openExternalBrowser",
        desc: url
    };
    flutterShowToast(param)
    console.log("打开浏览器",param)
}

//复制文本
export function copyText(str) {
    let input = document.createElement("input");
    input.value = str;
    document.body.appendChild(input);
    input.style.height = "0px"
    input.style.position = "absolute"
    input.style.opacity = 0
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand('Copy');
    document.body.removeChild(input)
}

export function loadImages(urlArr,callback){ //参数 图片地址数组,图片加载完成后回调
    if (urlArr.length <= 0) return
    let i = 0,
        timer = null,
        len = urlArr.length,
        load = (url) => {
            if (i < len) {
                const image = new Image()
                image.src  = url
                timer = setInterval(() => {
                    if (image.complete) {
                        // console.log('complete')
                        clearInterval(timer)
                        load(urlArr[i++])
                    }
                }, 80)
            } else {
                // this.showLoading = false // 所有图片加载完后的操作
                // savePoster()
                // setTimeout(()=>{
                //     Toast.clear()
                // })
                callback()
            }
        }
    load(urlArr[i])
}



